
import { defineComponent, PropType, ref } from "vue";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { IOrder } from "@/store/order/state";
import { orderItemStatuses } from "@/store/order/state";

export default defineComponent({
  name: "OrderViewModal",
  components: {
    Dialog,
    Column,
    DataTable,
  },
  props: {
    order: {
      type: Object as PropType<IOrder>,
      required: true,
    },
  },
  setup() {
    const isVisible = ref(true);
    return {
      isVisible,
      orderItemStatuses,
    };
  },
});
