import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: 'Заказ #' + _ctx.order.number,
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isVisible) = $event)),
    modal: true,
    onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('hide'))),
    style: { width: '50vw' }
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Закрыть",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide')))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        ref: "dt",
        value: _ctx.order.OrderItems
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: "Товар"
          }),
          _createVNode(_component_Column, {
            field: "barcode",
            header: "Штрихкод"
          }),
          _createVNode(_component_Column, {
            field: "quantity",
            header: "Кол-во"
          }),
          _createVNode(_component_Column, {
            field: "priceRetail",
            header: "Цена"
          }),
          _createVNode(_component_Column, {
            field: "status",
            header: "Статус"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.orderItemStatuses[data.status]), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}